<template>
  <div>
    <a-affix :offset-top="0">
      <a-page-header class="header-affix mb-4">
        <a-form>
          <div class="row">
            <div class="col-12 mb-4">
              <div class="row">
                <div class="col-md-2">
                  <a-input v-model:value="modifierSearchName" placeholder="Название" @change="searchModifierName" allow-clear />
                </div>
                <div class="col-md-2">
                    <a-select
                      show-search
                      v-model:value="queryParams.group_id"
                      placeholder="Группа"
                      @change="searchModifierGroup"
                      :options="groupsFilter"
                      style="width: 100%"
                    />
                </div>
                <div class="col-md-8 text-right">
                  <a-button @click="clearFilters" class="w-100 text-right" shape="round" type="link">
                    <template #icon>
                      <CloseCircleOutlined/>
                    </template>
                    Сбросить фильтр
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form>
      </a-page-header>
    </a-affix>
    <vb-edit-modifier
      :key="editModalKey"
      :modal="modalVisible"
      :modifier-id="modifierId"
      :groups="groupsFilter"
      :locales="locales"
      @reload="reload()"
      @hide-modal="hideModal()"
    />
    <vb-edit-modifiers
      :key="multipleEditModalKey"
      :modal="multipleModalVisible"
      :modifier-ids="modifierIds"
      :groups="groupsFilter"
      @reload="reload();clearSelection()"
      @hide-modal="hideMultipleModal()"
    />
    <div class="clearfix">
      <a-dropdown
        class="my-2"
        v-if="selectedRowKeys.length"
      >
        <template #overlay>
          <a-menu>
            <a-menu-item key="1" @click="multipleUpdateModal">
              Редактировать
            </a-menu-item>
            <a-menu-item key="3" @click="deleteModifiers">
              Удалить выбранные модификации
            </a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary">
          Выберите действие
          <DownOutlined/>
        </a-button>
      </a-dropdown>
      <a-typography-text v-if="selectedRowKeys.length" secondary class="mx-3">
        (Выделено: {{ selectedRowKeys.length }} элементов)
      </a-typography-text>
      <a-button
        v-if="showSelectAllButton"
        @click="selectAll"
        type="link"
      >Выделить все ({{ total }})
      </a-button>
      <a-button
        v-if="selectedRowKeys.length"
        @click="clearSelection"
        type="text"
        class="text-red"
      >
        Отменить
      </a-button>
    </div>
    <div class="clearfix">
      <a-button @click="addModalVisible = true" class="float-right" type="primary">
        Добавить модификаций
      </a-button>
      <a-modal
          class="spic-modal"
          title="Добавить модификаций"
          :visible="addModalVisible"
          :closable="true"
          :confirm-loading="false"
          :ok-button-props="{}"
          ok-text="Добавить"
          @ok="add"
          @cancel="addModalVisible = false"
      >
        <a-form>
          <div class="row">
            <div class="col-md-12">
              <a-form-item label="Выберите модификаций">
                <a-select
                    v-model:value="productModifiers"
                    placeholder="Модификации"
                    mode="multiple"
                    :options="modifiers"
                    allow-clear
                    style="width: 100%"
                />
              </a-form-item>
            </div>
          </div>
        </a-form>
      </a-modal>
    </div>
    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      :expand-row-by-click="true"
      :custom-row="(record) => {
        return {
          ondblclick: () => {
            updateModal(record['id']);
          }
        }
      }"
      @change="tableChange"
      row-key="id"
      id="data-table-modifiers"
    >
      <template #title>
        <div class="pb-1 overflow-hidden">
          <h6 class="pull-left mb-0">
            <router-link :to="{ name: 'categories', params: { brand: route.params.brand } }">Категории</router-link>
          </h6>
          <h6 class="pull-left mb-0">
            <RightOutlined :style="{ fontSize: '10px', margin: '4px 7px 0', display: 'block' }"/>
          </h6>
          <h6 class="pull-left mb-0">
            <router-link :to="{ name: 'products', params: { category: route.params.category } }">Продукты</router-link>
          </h6>
          <h6 class="pull-left mb-0">
            <RightOutlined :style="{ fontSize: '10px', margin: '4px 7px 0', display: 'block' }"/>
          </h6>
          <h6 class="font-weight-light pull-left mb-0 text-grey">Модификации</h6>
        </div>
      </template>
      <template #description="{ record }">
        <div v-if="editDescId === record.id" class="row">
          <div class="col-md-10 px-0">
            <div class="row">
              <div class="col-md-10 px-0">
                <a-input
                  :id="`desc-${editDescId}`"
                  @keydown.esc="editDescId = 0"
                  @pressEnter="updateColumn(record['uuid'], record.name, 'description', record.description); editDescId = 0"
                  v-model:value="record.description"></a-input>
              </div>
              <div class="col-md-2 justify-content-end row p-0">
                <a-button
                  @click="updateColumn(record['uuid'], record.name, 'description', null); editDescId = 0"
                  type="link"
                ><CloseCircleOutlined /></a-button>
              </div>
            </div>
          </div>
          <div class="col-md-2 justify-content-end row p-0">
            <a-button
              @click="updateColumn(record['uuid'], record.name, 'description', record.description); editDescId = 0"
              type="text" shape="round" class="save-button">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else>
          <div class="pull-left" @click="onEditInput('desc', record.id)" :style="{ lineHeight: '30px' }">{{ record.description }}</div>
          <a-button @click="onEditInput('desc', record.id)" type="text" shape="round" class="px-2 pull-right">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #name="{ record }">
        <div v-if="editNameId === record.id" class="row">
          <div class="modifier-info">
<!--            <div class="modifier-image" :style="'background-image: url(' + record['image_url'] + ')'"></div>-->
            <a-input :id="`name-${editNameId}`" @pressEnter="updateColumn(record['uuid'], record.name); editNameId = 0"
                     v-model:value="record.name" @keydown.esc="editNameId = 0"></a-input>
            <a-button @click="updateColumn(record['uuid'], record.name); editNameId = 0" type="text" shape="round"
                      class="save-button">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else class="row">
          <div class="modifier-info">
            <div class="modifier-title">
<!--              <div class="modifier-image" :style="'background-image: url(' + record['image_url'] + ')'"></div>-->
              <span @click="onEditInput('name', record.id)" class="modifier-name cursor-pointer">{{ record.name }}</span>
            </div>
            <a-button v-if="false" @click="onEditInput('name', record.id)" type="text" shape="round" class="px-2 pull-right">
              <template #icon>
                <EditOutlined/>
              </template>
            </a-button>
          </div>
        </div>
      </template>
      <template #group="{ record }">
        <div v-if="editGroupId === record.id" class="row">
          <div class="col-md-9 px-0">
            <a-select
              v-if="record.group"
              :id="`group-${editGroupId}`"
              v-model:value="record.group['name']"
              placeholder="Выберите группу"
              style="width: 100%"
              :filter-option="false"
              :options="groupsFilter"
              @keydown.esc="editGroupId = 0"
              show-search
            >
            </a-select>
          </div>
          <div class="col-md-3 justify-content-end row p-0">
            <a-button
              v-if="record.group"
              @click="updateColumn(record['uuid'], record.name, 'group_id', record.group?.['name']); editGroupId = 0"
              type="text" shape="round" class="save-button">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else class="container-fluid">
          <div class="row">
            <a-select
              class="col-md-10"
              v-if="record.group"
              v-model:value="record.group['name']"
              placeholder="Выберите группу"
              style="width: 100%"
              :filter-option="false"
              :options="groupsFilter"
              disabled
              show-search
            >
            </a-select>
            <a-button v-if="false" @click="onEditInput('group', record.id)" type="text" shape="round" class="px-2 pull-right col-md-2">
              <template #icon>
                <EditOutlined/>
              </template>
            </a-button>
          </div>
        </div>
      </template>
      <template #price="{ record }">
        <div v-if="editPriceId === record.id" class="row">
          <div class="col-md-10 px-0">
            <a-input :id="`price-${editPriceId}`"
                     @keydown.esc="editPriceId = 0"
                     @pressEnter="updateColumn(record['uuid'], record.name, 'price', record.price); editPriceId = 0"
                     v-model:value="record.price"></a-input>
          </div>
          <div class="col-md-2 justify-content-end row">
            <a-button @click="updateColumn(record['uuid'], record.name, 'price', record.price); editPriceId = 0"
                      type="text" shape="round" class="save-button">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else>
          <div class="pull-left" @click="onEditInput('price', record.id)" :style="{ lineHeight: '30px' }">{{ formattedBalance(record.price) }}</div>
          <a-button @click="onEditInput('price', record.id)" type="text" shape="round" class="px-2 pull-right">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #action="{ record }">
        <div class="w-100 text-right">
          <a-tooltip placement="top" class="mx-2">
            <template #title>Редактировать</template>
            <a @click="updateModal(record['id'])"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Удалить</template>
            <a @click="deleteModal(record['id'])"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
  </div>
</template>

<script setup>
import {
  DownOutlined,
  EditOutlined,
  RightOutlined,
  SaveOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons-vue';
import {computed, onMounted, reactive, ref, unref, watch, toRefs, nextTick} from 'vue';
import VbEditModifier from "@/views/store/menu/modifiers/edit.vue";
import VbEditModifiers from "@/views/store/menu/modifiers/multiple-edit.vue";
import {useRoute, useRouter} from "vue-router";
import {message, Modal} from 'ant-design-vue';
import apiClient from "@/services/axios";
import {useStore} from "vuex";

let
  timer = null,
  groupsFilter = ref([]),
  modifierSearchName = ref(''),
  showSelectAllButton = ref(false),
  editNameId = ref(0),
  editGroupId = ref(0),
  editDescId = ref(0),
  editPriceId = ref(0),
  modifierId = ref(0),
  modifierIds = ref([]),
  locales = ref([]),
  dataSource = ref([]),
  imageUrl = ref(''),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  addModalVisible = ref(false),
  modifiers = ref([]),
  productModifiers = ref([]),
  multipleModalVisible = ref(false),
  labelCol = {span: 9},
  wrapperCol = {span: 13},
  total = ref(0),
  editModalKey = ref(0),
  multipleEditModalKey = ref(0);

const router = useRouter(),
  selectedRowKeys = ref([]),
  user = computed(() => useStore().getters['user/user']),
  searchInput = ref(),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    name: '',
    group_id: '',
  },
  searchModifierName = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({name: e.target.value})
    }, 800);
  },
  searchModifierGroup = (value) => {
    updateParams({ group_id: value })
  },
  queryParams = reactive({...initialParams}),
  clearFilters = () => {
    Object.assign(queryParams, initialParams);

    modifierSearchName.value = '';
  },
  columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      fixed: 'left',
      sorter: true,
      slots: {customRender: 'name'},
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      width: 130,
      sorter: true,
      slots: {customRender: 'price'},
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      key: 'added_on',
      width: 200,
      sorter: true,
      slots: {customRender: 'date'},
    },
    {
      title: 'Группа',
      dataIndex: 'group_id',
      key: 'group_id',
      width: 100,
      sorter: true,
      slots: {customRender: 'group'},
    },
    {
      key: 'action',
      fixed: 'right',
      width: 100,
      slots: {customRender: 'action'},
    },
  ],
  formattedBalance = (balance, penny = true) => {
    if (penny)
      balance = balance / 100;
    let formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS',
    });
    let formattedBalance = formatter.format(balance)
    return formattedBalance.substring(0, formattedBalance.length - 4) + ' UZS'
  },
  formattedDatetime = (date) => {
    date = date.replace(/ /g, "T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  deleteModifiers = () => {
    Modal.confirm({
      title: 'Удаление модификаций',
      content: `Вы действительно хотите удалить выбранные модификации?`,
      okText: 'Да',
      cancelText: 'Отменить',
      async onOk() {
        return apiClient.delete('modifier/multiple-delete?product=' + route.params.product, {
          data: { modifiers: selectedRowKeys.value },
        }).then(response => {
          if (response) {
            message.success('Модификации успешно удалены')
          }
        }).catch(() => {
            // console.log(err)
        }).finally(() => {
          reload()
          clearSelection()
        });
      },
    });
  },
  onSelectChange = changeableRowKeys => {
    selectedRowKeys.value = changeableRowKeys;
  },
  rowSelection = computed(() => {
    return {
      selectedRowKeys: unref(selectedRowKeys),
      onChange: onSelectChange,
      hideDefaultSelections: true,
      selections: null,
    };
  }),
  clearSelection = () => {
    selectedRowKeys.value = []
  },
  selectAll = () => {
    const params = {...queryParams}
    return apiClient.get(`/modifier/modifier-ids?product_id=${route.params.product}`, { params }).then(({data}) => {
      if (data['data']) {
        selectedRowKeys.value = data['data']
      }
    })
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({sort: sign + sorter.field})
    }
  },
  updateColumn = (id, name, column = null, value = null) => {
    let data = {};
    data['name'] = name;
    if (column) {
      data[column] = value;
    }
    apiClient.put(`/modifier/update?modifier=${id}`, data).then(response => {
      if (response) {
        message.success('Модификация успешно обновлена')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  updateModal = (id) => {
    modifierId.value = id;
    modalVisible.value = true;
  },
  multipleUpdateModal = () => {
    modifierIds.value = selectedRowKeys.value;
    multipleModalVisible.value = true
  },
  add = () => {
    const product = route.params.product;
    return apiClient.post(`modifier/add?product=${product}`, {
      modifiers: productModifiers.value,
    }).then(() => {
      addModalVisible.value = false;
      // console.log(response)
    }).catch(() => {
      addModalVisible.value = true;
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  deleteModal = (id) => {
    Modal.confirm({
      title: 'Удаление модификации',
      content: `Вы действительно хотите удалить модификацию?`,
      okText: 'Да',
      cancelText: 'Отменить',
      onOk() {
        const product = route.params.product
        return apiClient.delete(`modifier/delete?product=${product}&modifier=${id}`).then(response => {
          if (response) {
            message.success('Модификация успешно удалена')
          }
        }).catch(() => {
          // console.log(err)
        }).finally(() => {
          reload()
        });
      },
    });
  },
  hideModal = () => {
    setTimeout(() => {
      modalVisible.value = false
    }, 100)
  },
  hideMultipleModal = () => {
    setTimeout(() => {
      multipleModalVisible.value = false
    }, 100)
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get(`/modifier/index?product=${route.params.product}`, { params }).then(({data}) => {
      dataSource.value = data.data.items
      groupsFilter.value = data.data.groups
      modifierId.value = data.data.store
      editModalKey.value++
      multipleEditModalKey.value++
      total.value = data.data.total
      modifiers.value = data.data.modifiers
      productModifiers.value = data.data['selected_modifiers']
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  onEditInput = (type, id) => {
    switch (type) {
      case "name":
        editNameId.value = id;
        break;
      case "price":
        editPriceId.value = id;
        break;
      case "group":
        editGroupId.value = id;
        break;
    }
    nextTick(() => {
      const input = document.getElementById(`${type}-${id}`);
      if (input)
        input.focus();
    });
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
  getData({
    ...queryParams,
  })
})

watch(selectedRowKeys, (value) => {
  showSelectAllButton.value = value.length === queryParams['per-page']
})

watch(queryParams, () => {
  router.push({
    name: 'modifiers',
    params: {
      category: route.params.category,
      product: route.params.product,
    },
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
});

const query = {...toRefs(queryParams)}

</script>
<style lang="scss">
@import "./index.css";

.header-affix {
  border: 1px solid #e4e9f0;
  background-color: #ffffff !important;
  box-shadow: 0 4px 10px 0 #14130b0d, 0 0 10px 0 #1413110f;
  border-radius: 0.25rem;
}

[data-vb-theme="default"] .ant-select-item-option-content {
  white-space: normal;
}

[data-vb-theme="default"] .ant-select-selection-overflow-item {
  max-width: 90%;
}

.modifier-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 230px;
}

.modifier-info {
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.modifier-title {
  height: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.modifier-image {
  min-height: 40px;
  width: 120px;
  height: 40px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  display: inline-block;
  float: left;
}

.cursor-pointer {
  cursor: pointer;
}

.save-button {
  width: 32px;
  height: 32px;
  padding: 4px 6px !important;
}
</style>
